.home-container {
  min-width: 500px;
  min-height: 65vh;
  /* align-items: center; */
}

.ask_button {
  width: 200px;
  height: 41px;
  background-color: #516cf0;
  color: white;
  border: none;
  border-radius: 4px;
  text-align: center;
  border: 1px solid transparent;
  font-size: 1rem;
}
.ask_button:hover {
  background-color: #ff8500;
}
.ask_button:active {
  border: 3px solid #516cf0;
}
hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

/* .avatar-container {
  display: block;
  align-items: center;
  margin: 35px auto;
  position: relative;
  right: -55px;
}

.avatar {
  border-radius: 100%;
  width: 90px;
  position: relative;
  right: -140px;
  height: 90px;
  padding: 0px;
  object-fit: cover;
}

.welcome-text {
  white-space: nowrap;
}

@media (max-width: 767px) {
  .avatar-container {
    position: relative;
    left: -5px;
    flex-direction: column;
    align-items: flex-start;
  }

  .welcome-text {
    margin-top: 10px;
    margin-left: 0;
  }

  .avatar {
    margin-bottom: 10px;
  }
} */
